@import "_vars.scss";

#reel-categories {
  margin-top: 5px;
  width: 100vw;

  span {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(15px);
    border-radius: 50px;
    color: #fff;
    padding: 5px 25px;
    font-size: 14px;
    margin: 0 5px;
    margin-bottom: 5px;
    display: inline-block;
    white-space: nowrap;

    &:hover {
      opacity: 0.8;
      @media screen and (max-width: 768px) {
        background: rgba(0, 0, 0, 0.4);
      }
    }

    &.active {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

#reel-bar {
  height: 150px;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow-x: auto;
  z-index: 2;
  transition: height 0.3s ease;

  &::-webkit-scrollbar-track {
    background: var(--color-primary);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: var(--color-secondary);
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: var(--scroll-bar-size);
    background: var(--color-primary);
  }

  &.white {
    background: rgba(255, 255, 255, 0.75);

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.75);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #319fd8;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: var(--scroll-bar-size);
      background: var(--color-primary);
    }
  }

  .scroll-div {
    width: auto;
    height: 100%;
  }

  .preview {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 106px;
    width: 160px;
    margin: 0 5px; //DO NOT CHANGE, Tour and Game are depended on this!
    margin-top: 6px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    overflow: hidden;

    &.active {
      border: 2px solid var(--color-secondary);
    }

    svg,
    i,
    img {
      color: #fff;
      height: 16px;
      position: absolute;
      left: 7px;
      top: 7px;
      filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
    }

    span {
      color: #fff;
      position: absolute;
      bottom: 2px;
      left: 0;
      font-size: 14px;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
      width: 100%;
      text-align: center;
    }
  }
}

#bottom-bar {
  transition: opacity 0.33s ease;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  transition: bottom 0.3s ease;

  .data-balloon:before,
  .data-balloon:after {
    font-family: "Heebo", sans-serif !important;
    @media screen and (max-width: 768px) {
      display: none !important;
      opacity: 0 !important;
    }
  }
  &.hide {
    display: none;
  }
  &.smallIcons {
    .btn, .action-icon {
      width: 30px !important;
      height: 30px !important;
    }
    .btn-auto-width {
      width: auto !important
    }
    .action-icons.right {
      bottom: 80px;
    }
  }
  &.open {
    bottom: 150px;
    .action-icons {
      bottom: 50px !important;
    }

    @media screen and (max-width: 768px) {
      .actions-bar {
        bottom: 0 !important;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    min-width: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    @media screen and (max-width: 768px) {
      margin-left: 3px;
      margin-right: 3px;
      width: 35px;
      height: 35px;
    }

    &.active {

      &.reel {
        transform: rotate(180deg);
      }
    }

    svg,
    i {
      margin-top: -1px;
    }

    &:not(:disabled):hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    .panorama-icon {
      width: 16px;
      height: 16px;
    }
  }

  .more-bar,
  .actions-bar {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 5px;
    pointer-events: auto;
    transition: all 0.3s ease;
    z-index: 2;
    @media screen and (max-width: 768px) {
      left: 0;
    }

    &.white {
      background: rgba(255, 255, 255, 0.75);
    }

    &.active {
      overflow: visible;

      .actions-btn {
        background-color: var(--color-secondary);
      }
    }
  }

  .actions-bar {
    bottom: 0;
    width: 100%;
    overflow-x: auto;
    max-width: calc(100vw - 130px);

    &::-webkit-scrollbar {
        display: none;
      }

    .main-icons {
      display: flex;
      padding-bottom: 5px;
    }

    & > div {
      overflow-x: auto;

      &::-webkit-scrollbar-track {
        background: var(--color-primary);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: var(--color-secondary);
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background: var(--color-primary);
      }
    }

    .active-sg-title {
      font-weight: 300;
      color: white;
      font-size: 26px;
      font-style: normal;
      filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 1));
    }

    @media screen and (max-width: 768px) {
      bottom: 30px !important;
    }
  }

  .more-bar {
    bottom: 50px;
    overflow: visible;
    @media screen and (max-width: 768px) {
      bottom: 80px;
    }

    &.categoriesOn {
      bottom: 80px;
    }

    .more-btn {
      @media screen and (max-width: 768px) {
        width: 35px;
        height: 35px;
        background: rgba(0, 0, 0, 0.3);
      }
      transition: all 0.3s ease;
    }

    .more-btn.active {
      transform: rotate(90deg);
    }
  }

  .floors-modal,
  .languages-modal {
    position: absolute;
    bottom: 45px;
    left: 0;
    min-width: 100px;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px 10px;
    border-radius: 15px;
    z-index: 3;
    text-align: center;

    ul {
      margin: 0;
      max-width: 200px;

      li {
        color: white;
        cursor: pointer;
        padding: 5px 0;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
          opacity: 0.7;
        }

        &.active {
          color: var(--color-secondary);
        }
      }
    }
  }

  .languages-modal {
    ul li {
      a {
        color: white;
      }

      &.active {
        a {
          color: var(--color-secondary);
        }
      }
    }
  }

  .youtube > .fa-times {
    position: absolute;
    right: -10px;
    top: -10px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    color: #fff;
    padding: 8px;
    font-size: 14px;
    line-height: 10px;
    z-index: 2;

    &:hover {
      background: var(--color-secondary) !important;
    }
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .actions-bar {
      bottom: 40px !important;
    }
  }
}

.pdf-list {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 115px;
  height: auto;
  bottom: 0;
  left: 60px;
  text-align: left;
  border-radius: 6px;
  z-index: 3;

  &.wide {
    width: 200px;
  }
}

.pdf-list a {
  color: #fff !important;
  width: 100%;
  font-size: 12px;
  display: block;
  padding: 0 10px;
  font-weight: normal;
  text-shadow: none;
  margin: 15px 0;
  cursor: pointer;
}

.pdf-list a:hover {
  color: var(--color-secondary) !important;
}

.action-icons {
  display: flex;
  position: absolute;
  z-index: 10;
  bottom: 40px;
  padding: 0 5px;
  z-index: 1;

  &.right {
    right: 0px;
  }

  &.left {
    left: 10px;
    @media screen and (max-width: 768px) {
      left: 0;
    }
  }

  &.more {
    bottom: 50px;
    left: 0;
    visibility: hidden;

    &.active {
      visibility: visible;
    }

    .more-menu {
      position: absolute;
      bottom: -10px;

      .action-icon {
        margin: 5px;
      }
    }
  }

}
.action-icon {
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 0 5px;
  text-align: center;
  &.full-screen-icon {
    top: -35px;
    position: absolute;
    right: 0;
  }


  @media screen and (max-width: 768px) {
    margin-left: 3px;
    margin-right: 3px;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0.3);
  }

  &:hover,
  &.active {
    background: var(--color-secondary);
  }

  svg,
  i {
    color: #fff;
    vertical-align: middle;
  }

  img {
    width: 18px;
  }

  &.music .music-status {
    font-size: 7px;
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
    border: solid 1.5px #f8f8f8;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: -3px;
    left: -3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: var(--color-secondary);
    }
  }
}

#bottom-bar-help {
  display: flex;
  align-items: center;
  position: fixed;
  overflow: hidden;
  bottom: 0;
  width: auto;
  right: 0;
  border-radius: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  height: 30px;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  padding: 0 10px;
  z-index: 999;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 375px) {
    padding: 0;
  }

  button {
    display: inline-block;
    color: #fff;
    font-size: 10px;
    margin: 0 8px;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      color: var(--color-secondary);
    }
  }

  img {
    vertical-align: middle;
    height: 22px;
    margin: 0 8px;
    display: inline-block;

    &.mp-logo {
      height: 12px;
    }
  }

  .btn {
    width: auto;
    border-radius: 0;
    padding: 0 5px;
    background: none;

    &:hover {
      background: none;
      color: #fff;
      opacity: 0.7;
    }
  }

  .preview {
    background-size: contain;
  }
}
