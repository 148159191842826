@keyframes pulse {
  from {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}

#video-overlay {
  overflow: hidden;
  .loader {
    width: 100% !important;
  }
  .notifications {
    background: red;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    top: -8px;
    left: -8px;
    color: white;
    font-family: 'Heebo', sans-serif;
  }

  .banner-wait-host {
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    color: white;
    z-index: 101 !important;
    background: rgba(0, 0, 0, 0.95) !important;
    &.open {
      display: flex !important;
    }
  }
}

#video-name-overlay {
  display: block !important;
  z-index: 1001 !important;
}
.users-list {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 0;
  bottom: 100px;
  padding: 10px;
  width: 150px;
  height: 200px;
  z-index: 4;
  overflow-y: scroll;
  li {
    color: #fff;
    margin: 5px 0;
  }
}
#public-tour-root .chat,
#ecommerce-tour-root .chat
{

  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  right: -400px;
  bottom: 80px;
  height: 60vh;
  z-index: 111;
  transition: 0.3s ease all;
  padding: 20px;

  backdrop-filter: blur(36px);
  border-radius: 15px;
  width: 400px;

  .fas.fa-times {
    right: 24px;
    color: white;
    font-size: 30px;
    position: absolute;
    cursor: pointer;
    top: 15px;
  }

  @media screen and (max-width: 400px) {
    //background: #fff;
    bottom: -70vh;
    height: 70vh;
    width: 100%;
    right: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  h3 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 10px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.open {
    right: 50px;
    @media screen and (max-width: 768px) {
      bottom: 0;
      right: 0;
    }
  }
  .socket-connection {
    color: #fff;
    font-size: 10px;
    padding: 0 10px;
    text-align: right;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50px;
  }
  .messages {
    height: calc(100% - 125px);
    margin-top: 30px;
    position: relative;
    top: 0;
    right: 0;
    overflow-y: scroll;
    padding: 10px;
    padding-bottom: 0;
    width: 100%;

    &::-webkit-scrollbar-track {
      background: var(--color-primary);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: var(--color-secondary);
    }
    &::-webkit-scrollbar {
      width: var(--scroll-bar-size);
      background: var(--color-primary);
    }

    .messge-wrap {
      display: flex;
      margin-bottom: 15px;
      max-width: 300px;
      overflow: hidden;

      &.own-message {
        max-width: initial;
        margin-left: 35px;
        justify-content: end;
        align-self: end;

        img {
          display: none;
        }
        .message {
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 0;
        }
      }

      img {
        //width: 50px;
        //height: 50px;
        //object-fit: cover;
        //border-radius: 50%;
        //object-position: 0px -3px;

        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: contain;
        //object-position: 10px 6px;
        scale: 1.6;
        &[src="/images/face_1.png"] {
        scale: 1;
      }
      }

      .message {
        //width: 100%;
        margin: 0;
        background: #0a001933;
        color: #fff;
        display: block;
        line-height: 1.4;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        padding: 5px 15px 5px 30px;


        .name {
          color: #fff;
          margin-right: 6px;
          display: block;
          width: 100%;
          font-size: 14px;
          span {
            float: right;
          }

          .name-itself {
            float: initial;
            color: #98b2ff;
          }
        }
        .content {
          font-size: 15px;
          margin-top: 5px;
        }
      }
    }
  }
  .send {
    @include hCenter(relative);
    height: 48px;
    margin: 20px 0 0;
    width: 100%;
    background: #4e4e4e;
    color: white;

    bottom: 0;
    left: 0;
    border-radius: 50px;
    background: var(--color-primary);
    top: 8px;
    input {
      width: 100%;
      float: left;
      height: 100%;
      margin: 0;
      background: none;
      font-family: 'Heebo', sans-serif !important;
      font-weight: 300;
      color: #fff;
    }
    img {

      width: 42px;
      height: 42px;
      border-radius: 50%;
      position: absolute;
      right: 3px;
      top: 3px;
      cursor: pointer;
      //background: #ffffff26;
      padding: 5px;

    }
  }
}
.videos {
  position: fixed;
  left: 5px;
  top: 5px;
  z-index: 99;
  width: 160px;

  .partner-video .loader {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;

    span {
      background: none;
      animation: rotate 1s linear infinite;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 10px;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    width: 220px;

    .partner-video {
      width: 100px;
      height: 85px;
      margin: 5px;
    }
  }
  @media screen and (max-height: 600px) {
    width: 220px;

    .partner-video {
      width: 100px;
      height: 85px;
      margin: 5px;
    }
  }
  @media screen and (min-height: 1000px) {
    width: 200px;
  }
  & > div {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 6px;
    height: 130px;

    video {
      z-index: 1;
      position: relative;
      width: 100% !important;
      height: 100%;
      padding: 1px;
      background-color: #000;
      display: block;
      object-fit: cover;
    }
    &.full {
      @include superCenter(fixed);
      @include fullpage;
      background: #000;
      z-index: 100;
      video {
        height: 100%;
        max-height: 100% !important;
      }
      .full-screen {
        color: #fff;
        font-size: 20px !important;
        position: absolute;
        top: 30px !important;
        left: 30px !important;
        bottom: auto !important;
        right: auto !important;
        z-index: 10;
        cursor: pointer;
      }
    }

    .partner-video-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      width: 100%;
      height: 20px;
      background-color: rgba(0,0,0,0.7);
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      span {
        width: 100%;
        padding: 5px;
        font-size: 13px;
        color: #fff;
        z-index: 3;
        @media screen and (max-width: 768px) {
          padding: 3px;
          font-size: 11px;
        }
      }
      i {
        cursor: pointer;
        font-size: 11px;
        padding: 0;
        color: #fff;
        height: 100%;
        margin: 0 2px;
        min-width: 14px;

        &.fas.fa-microphone-slash {
          min-width: 17px;
        }
      }
    }
    .video-off {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.7);

      p {
        font-size: 18px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

#public-tour-root .countDownWrap,
#ecommerce-tour-root .countDownWrap
{
  display: flex;
  text-align: center;
  justify-content: center;
  position: fixed;
  margin: 0 auto;
  right: 0;
  left: 0;
  z-index: 103;
  top: 0;
  padding: 0;
  height: auto !important;
  width: auto !important;
  background: transparent;
  @media screen and (max-width: 768px) {
    justify-content: right !important;
    left: auto;
    margin-right: 5px;
  }
  .primary-btn {
    position: relative;
    border-radius: 50px;
    margin-left: 10px;
    top: 10px;
    height: 40px;
  }
  .join-btn {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .socket-connection {
    position: absolute;
    font-size: 10px;
    width: 100px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    margin-top: 2px;
  }
  .countDown {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50px;
    top: 10px;
    height: 40px;
    position: relative;
    padding: 5px 10px;
    line-height: 30px;
    width: auto !important;
    z-index: 1;
    color: #fff;
    i {
      color: red;
      cursor: pointer;
      position: relative;
      margin: 0 3px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      height: 30px !important;
      width: 30px !important;
      line-height: 30px;
      &.on {
        color: #0ff10f;
      }
    }

    .participants-number {
      margin: 8px 7px 8px 0;
    }
  }
}
.game-container .countDownWrap{
  justify-content: flex-start !important;
}

.game-container .game-tour-timer{
  @media screen and (max-width: 768px) {
    right: unset !important;
    padding: 6px !important;
  }
  @media screen and (max-width: 425px) {
    left: unset !important;
    right: 0 !important;
    top: 57px !important;
  }
}
