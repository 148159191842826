//login form
#global-fullscreen #public-tour-root .game-tour-main {
  background: url("/login.png") center center / cover no-repeat fixed rgb(0, 0, 0);
  height: 100vh;
  overflow: auto;

  .rtl-direction {
    // text-align: right;
    direction: rtl;
  }

  .rtl-text-align {
    text-align: right;
    direction: rtl;
  }

  .game-tour-form {
    min-width: 300px;
    padding-top: 100px;
    color: #ffffff;
    text-align: center;
  }

  .game-tour-gameName {
    margin: 15px 0 0;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #f8f8f8;
  }

  .game-tour-welcome {
    margin: 0 0 15px;
    opacity: 0.6;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #f8f8f8;
  }

  .game-tour-input::placeholder {
    color: #d9d9d9;
  }

  .game-tour-input {
    font-family: "Roboto", sans-serif;
    margin: 36px 0 40px 0;
    padding: 22px 0 22px 0;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    height: 68px;
    font-size: 20px;
    font-weight: 500;
  }

  .game-tour-buttons-wrap {
    text-align: left;
  }

  .game-tour-btn:focus {
    //outline: 1px solid rgba(255, 255, 255, 0.1);
    outline: 0px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  }

  .game-tour-start {
    background-color: var(--color-secondary);
    color: #fff;
  }

  .game-tour-btn {
    height: 86px;
    font-size: 20px;
    border-radius: 5px;
    width: 48%;
    font-size: 26px;
    font-weight: 600;
  }

  .game-tour-tutorial {
    background-color: #d2d2d2;
    color: var(--color-secondary);
    float: right;
    border: 2px solid var(--color-secondary);
  }

  .game-tour-video {
    margin-top: 53px;
    height: 400px;
    margin-bottom: 100px;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &.game-endgame-screen {
    position: fixed;
    z-index: 1111;
    font-size: 35.5px;
    /*bigger*/

    .endgame-congratz {
      display: flex;
      justify-content: center;
      //margin-top: 100px;

      div:first-child {
        padding: 0px 15px;
        //background: #00ff0059;
        //border-radius: 3px;
        border-radius: 5px;
        /*bigger*/
        font-size: 45.5px;
        /*bigger*/
        height: 60px;
      }

      div:nth-child(2) {
        padding: 0px 15px;
        font-size: 45.5px;
        /*bigger*/
        white-space: nowrap;
        height: 60px;
      }

      &.mobile {
        flex-direction: column;
      }
    }

    .endgame-mgs1 {
      margin-top: 15px;
    }

    .endgame-score-mgs {
      margin-top: 35px;
    }

    .endgame-score-value {
      font-size: 67.5px;
      /*bigger*/
    }

    .endgame-time-mgs {
      margin-top: 25px;
    }

    .endgame-time-value {
      font-size: 67.5px;
      /*bigger*/
    }

    .endgame-credits-mgs {
      margin-top: 15px;
    }

    .endgame-credits-block {
      margin-top: 5px;
      //font-size: 37.5px;  /*bigger*/
      font-size: 32px;
      /*bigger*/
    }

    .endgame-buttons-block {
      margin-top: 15px;
      margin-bottom: 100px;
    }

    .endgame-buttons-block {
      display: flex;
      justify-content: center;

      .game-endgame-buttons {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;

        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin: 0px 5px;
        cursor: pointer;

        &.endgame-buttons-blue {
          background: #00ccff;
        }

        &.endgame-buttons-red {
          background: var(--color-secondary);

          margin-left: 2px;
          margin-bottom: 3px;
        }
      }
    }
  }

  .game-login-greeting-block {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;

    iframe {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 720px) {
    .game-tour-form {
      height: 100%;
      padding-top: 67px;
    }

    .game-tour-welcome {
      margin: 0;
      opacity: 0.6;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: center;
      color: #f8f8f8;
    }

    .game-tour-gameName {
      margin: 0;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: center;
      color: #f8f8f8;
    }

    .game-tour-video {
      margin-bottom: 0px;
      height: initial;

      iframe {
        //height: initial;
        height: 188px;
        //width: 100%;
      }
    }

    .game-tour-btn {
      width: 100%;
      margin-bottom: 20px;
      height: 60px;
      font-size: 20px;
    }

    .game-tour-input {
      height: 65px;
      font-size: 16px;
    }

    .game-tour-buttons-wrap {
      padding-bottom: 25px;
    }

    .game-login-greeting-block {
      overflow: auto;
    }
  }
}

@keyframes alert-toast-container-appear {
  0% {
    max-width: 0vw;
  }

  100% {
    max-width: 100vw;
  }
}

//game window
#global-fullscreen {
  .game-universal-show-button {
    position: absolute;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    bottom: 61px;
    z-index: 1;
    border-radius: 15px;
    text-align: center;
    padding-top: 4px;
    cursor: pointer;
  }

  .inner.mobile {
    .logo {
      display: none;
    }
  }

  .game-show-hint-button {

    &:hover,
    &.active {
      background-color: var(--color-secondary);
    }

    &.disabled {
      background-color: #d7d7d7;
    }

    left: 101px;
  }

  .game-show-backpack-button {

    &:hover,
    &.active {
      background-color: var(--color-secondary);
    }

    left: 61px;
  }

  .game-show-hint-modal {
    position: absolute;
    display: none;
    z-index: 999;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    color: #FFFFFF;

    &.active {
      display: block;
    }

    .game-hint-content-wrap {
      height: 100%;

      .game-modal-header-wrap {
        margin: auto;
        width: 558px;
        height: 35px;
        cursor: pointer;

        .game-hint-label {
          &.active {
            //border-bottom: 45px solid rgba(0, 0, 0, 0.7);
            border-bottom: 45px solid rgb(0, 0, 0);
            margin-top: -10px;
            z-index: 1;
            line-height: 46px;
          }

          display: inline-block;
          //border-bottom: 35px solid rgba(0, 0, 0, 0.3);
          border-bottom: 35px solid rgb(45, 45, 45);
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          height: 0;
          width: 207px;
          float: left;
          text-align: center;
          position: absolute;
          line-height: 33px;
        }

        .game-hintB {
          margin-left: 176px;
        }

        .game-solution {
          margin-left: 350px;
        }
      }

      .game-content-window-wrap {
        text-align: center;
        height: calc(100% - 35px);

        .game-content-wrap-2 {
          height: 100%;
          max-width: 100%;
          overflow: auto;
          display: inline-block;
          box-sizing: border-box;
        }

        .game-modal-content-wrap {
          iframe {
            width: 760px;
            height: 480px;
            //max-width: 100%;
          }

          background: rgba(0, 0, 0, .6);
          border-radius: 15px;
          width: auto;
          display: inline-block;
          padding: 15px;
          min-width: 585px;
        }
      }

      .game-hint-open-button {}

      .hint-modal-content-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;

        button {
          font-size: 14px;
        }
      }

      .game-hint-open-button {
        height: 35px;
        width: 138px;
        border-radius: 5px;
      }

      .hint-modal-close-button-wrap {
        margin-top: 10px;
        text-align: center;
      }

    }

  }

  .game-tour-timer {
    position: absolute;
    right: 0px;
    left: 0px;
    margin: 0 auto;
    top: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    color: #ffffff;

    &.withLockTimer {
      height: 250px;
    }

    i.fa-times {
      position: absolute;
      left: 22px;
      top: 27px;
      display: none;
      font-size: 25px;
    }

    .game-user-label {
      i.fa-user-circle {
        margin-right: 5px;
      }

      margin: 0 0 14px 0;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: right;
      color: #f8f8f8;
    }

    .game-timer-time-label {
      margin: 0 0 0 2px;
      opacity: 0.7;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: right;
      color: #f8f8f8;
    }

    .game-timer-time {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: right;
      color: #f8f8f8;
    }

    .game-timer-progress-label {
      opacity: 0.7;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: right;
      color: #f8f8f8;
    }

    .game-timer-progress {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: right;
      color: #f8f8f8;
    }

    @media only screen and (max-width: 720px) {
      &.minimized {
        width: 74px;
        height: 73px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;

        div {
          display: none;
        }
      }

      i.fa-times {
        display: block;
      }
    }

  }

  .game-tour-timer-with-logo{
    @media only screen and (max-width: 720px) {
      left: 90px;
    }
  }

  .game-condition-popup-modal {
    position: fixed;
    z-index: 1999;
    top: 0px;
    left: 0px;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    margin: auto;

    .game-condition-content-wrap {
      background: #00000075;
      padding: 15px;
      border-radius: 15px;
      display: inline-block;
      color: #FFFFFF;
      max-height: 100%;
      position: relative;

      //&.bigger {
      //  width: 760px;
      //  height: 420px;
      //}
      &.narrow {
        max-width: 70%;
        max-height: 80vh;
        overflow-y: auto;
        //font-size: 28px;
        font-size: 56px;
      }

      &>img {
        max-height: 94vh;
      }

      .exppp {
        top: 3px;
        right: 3px;
        position: absolute;
        font-size: 15px;
        color: white;
        background: black;
        z-index: 1111;
        border-radius: 50%;
        padding: 7px;
        line-height: 10px;
        cursor: pointer;
      }

      .gamecond-button-wrap {
        margin-top: 7px;
        text-align: center;
        font-size: 14px;
      }

      #condition-video-fullscreen {
        position: relative;

      }
    }
  }

  @media only screen and (max-width: 720px) {
    .game-show-hint-modal {
      min-width: 0px;
      width: 90%;
      left: 5%;
      max-width: initial;
      //height: 70%;
      overflow: hidden;

      &.active {
        z-index: 999;
      }

      .game-hint-content-wrap {
        .game-modal-header-wrap {
          height: 35px;
          padding-left: 10px;

          .game-hint-label {
            &.active {
              //border-bottom: 35px solid rgba(0, 0, 0, 0.7);
              border-bottom: 35px solid rgb(0, 0, 0);
              margin-top: 0px;
              z-index: 1;
            }

            &.game-hintB {
              margin-left: 87px;
            }

            &.game-solution {
              margin-left: 173px;
            }

            margin-top: 5px;
            //border-bottom: 30px solid rgba(0, 0, 0, 0.7);
            border-bottom: 30px solid rgb(45, 45, 45);
            width: 115px;
          }
        }
      }

      .game-content-window-wrap {
        .game-content-wrap-2 {
          min-width: 100%;

          .game-modal-content-wrap {
            iframe {
              width: 100%;
              height: 200px;
            }

            min-width: 100%;
          }
        }
      }
    }

    .game-universal-show-button {
      width: 35px;
      height: 35px;
      bottom: 90px;
      z-index: 2;
    }
  }

  .gameTrialErr {
    z-index: 999999999999999;
    color: black;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 25px;
    text-align: center;
    //margin-left: calc(50% - 240px);
    //margin-top: 100px;
    line-height: 1.2;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    flex-direction: column;

    .gameTrialErr-text {
      width: 460px;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
}

//modal portal
.game-tutorial-modal {
  overflow: visible;
  padding: 25px;
  width: 100%;
  height: 100%;

  i.fas.fa-times {
    top: 5px;
    right: 5px;
  }

  .game-tutorial-modal-wrap {
    position: relative;
    margin: auto;

    //width and height depending on configs
    iframe {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.game-time-is-up-modal {
  overflow: visible;
  padding: 25px;
  width: 100%;
  height: 100%;

  i.fas.fa-times {
    top: 5px;
    right: 5px;
  }

  .game-tutorial-modal-wrap {
    position: relative;
    margin: auto;

    //width and height depending on configs
    iframe {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }

 
  }
}

.game-bottom-bar {
  .game-actions-bar {
    bottom: 0;
    display: flex;
    position: absolute;
    padding: 5px;
    left: 10px;
    pointer-events: auto;
    transition: all 0.3s ease;
    z-index: 2;
    width: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgba(0, 0, 0, 0.3);

    .main-icons {
      &.categoriesOn {
        bottom: 10px !important;
      }
    }
  }

  .game-action-button-bar {
    width: 50px !important;
    height: 50px !important;

    i {
      font-size: 30px;
    }
  }

  .more-bar {
    bottom: 70px !important;

    .sound-volume {
      bottom: 80px !important;
    }
  }

  &.mobile {
    .game-action-button-bar {
      width: 30px !important;
      height: 30px !important;

      i {
        font-size: 20px !important;
      }
    }

    .more-bar {
      bottom: 50px !important;
    }
  }
}

.intro:not(.experienceVideo .intro) {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  z-index: 1111;
  color: white;
  background: black !important;
  display: flex;
  flex-direction: column;

  &.mobile {
    @include mobileHeightImportant;
  }

  .intro-btn-group {
    margin-top: 30px;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 3;
    background: transparent;
    cursor: pointer;
    color: white;
    font-size: 2rem;

    .intro-button {
      color: white;
      padding: 0;
      background-color: transparent;
      border: 0;
      margin: 0 10px;

      & span {
        margin-right: 10px;
      }

      &:hover {
        opacity: 0.6;
      }

      &:focus {
        outline: none;
      }

      i {
        font-size: 2rem;
      }
    }
  }

  #intro-clip-iframe {
    height: 95vh;

    &.mobile {
      width: 100vw;
      margin: auto;
      height: 100%;

      iframe {
        height: 100%;
      }
    }
  }
}